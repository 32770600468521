import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './PreContractualInfo.scss'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import creditDecisionServices from 'services/creditDecisionServices'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import getApplicationServices from 'services/getApplicationServices'
import documentServices from 'services/documentServices'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'

const PreContractualInfo = () => {
	const { t } = useTranslation()
	const [error, setError] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [confirmedTerms, setConfirmedTerms] = useState<boolean>(false)
	const applicationId = loanApplication.applicationData?.loanNumber

	const Loading = (loading: boolean) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_ERROR_AND_LOADING,
			payload: {
				...loanApplication,
				loading: loading,
			},
		})
	}

	const fetchApplicationAndDocumentData = async (applicationId: number) => {
		setLoading(true)
		try {
			await getApplicationServices
				.GetApplicationInformation(applicationId, t)
				.then((response) => {
					loanApplication.setApplicationData(response)
				})
			await documentServices
				.getDownloadDocuments(applicationId.toString(), t)
				.then((response) => {
					loanApplication.setDocumentData(response)
				})
		} catch (error) {
			console.warn(error)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const runCreditDecision = async () => {
		Loading(true)
		try {
			let response = await creditDecisionServices.runcreditdecision(
				applicationId!.toString(),
				cdaApiRoutes.runcreditcardcreditdecision
			)
			if (response?.status === 200) {
				dispatchLoanApplication({
					type: LOAN_APPLICATION_ACTIONS.SET_LOANOFFERDATA,
					payload: {
						...loanApplication,
						loanOfferData: response.data,
					},
				})
				await fetchApplicationAndDocumentData(applicationId!)
				dispatchMsp({
					type: MSP_ACTIONS.SET_INDEX,
					payload: {
						index: msp.index + 1,
					},
				})
			}
		} catch (error) {
			console.warn(error)
			setError(true)
			Loading(false)
		}
	}
	const next = () => {
		runCreditDecision()
	}

	return (
		<div className="pre-contractual-info">
			{loanApplication.loading || loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					<div className="pre-contractual-header">
						<h2>
							<Trans
								t={t}
								i18nKey={'PreContractualHeader'}></Trans>
						</h2>
					</div>
					<div className="pre-contractual-text">
						<Trans t={t} i18nKey={'PreContractualText'}></Trans>
					</div>
					<div className="pre-contractual-link">
						<a
							href="https://tfbank.es/media/sufnipfd/ine-tarjeta-de-cr%C3%A9dito-tf-bank.pdf"
							target="_blank"
							rel="noopener noreferrer"
							download>
							{t('PreContractualLink')}
						</a>
					</div>
					<br />
					<div className="pre-contractual-terms">
						<label className="checkbox-container">
							<input
								type="checkbox"
								checked={confirmedTerms}
								onChange={() =>
									setConfirmedTerms(!confirmedTerms)
								}
							/>
							<span className="checkmark"></span>
							<div>{t('PreContractualTerms')}</div>
						</label>
					</div>
					{error ? (
						<ErrorBox message={t('ErrorMsg')} />
					) : (
						<div className="pre-contractual-button-section">
							<button
								type="button"
								className="btn btn-primary"
								disabled={!confirmedTerms}
								onClick={() => next()}>
								{t('ContinueApplication')}
							</button>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default PreContractualInfo
